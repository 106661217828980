<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>售后管理</el-breadcrumb-item>
      <el-breadcrumb-item>审核历史</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card aaass">
      <div class="herd">
        <div class="tops riqi">
          <span>日期筛选：</span>
          <el-date-picker v-model="yue" type="month" @change="gettime" value-format="yyyy-MM" placeholder="选择月" clearable></el-date-picker>
        </div>
        <div class="tops">
          <span>报销公司：</span>
          <el-select v-model="company" placeholder="未选择" clearable @clear="handlefindComp">
            <el-option v-for="item in companis" :key="item.key" :label="item.val" :value="item.key" @click.native="findComp"></el-option>
          </el-select>
        </div>

        <div class="tops">
          <span>费用所属单位：</span>
          <el-select v-model="daqu" placeholder="未选择" clearable @clear="handledaqu">
            <el-option v-for="item in orgs" :key="item.key" :label="item.val" :value="item.key" @click.native="chdaqu"></el-option>
          </el-select>
        </div>

        <div class="tops">
          <span>费用种类：</span>
          <el-select v-model="feiyong" placeholder="未选择" clearable @clear="handlefeiyong">
            <el-option v-for="item in fees" :key="item.key" :label="item.val" :value="item.key" @click.native="chdafei"></el-option>
          </el-select>
        </div>
        <div class="tops">
          <span>报销人：</span>
          <el-select v-model="userid" placeholder="未选择" clearable @clear="handleuserid">
            <el-option v-for="item in renlist" :key="item.key" :label="item.val" :value="item.key" @click.native="choicedrug"></el-option>
          </el-select>
        </div>
        <div class="tops">
          <span>是否有发票：</span>
          <el-select v-model="fa" placeholder="未选择" clearable @clear="handlefa">
            <el-option v-for="item in bills" :key="item.key" :label="item.val" :value="item.key" @click.native="chdafapiao"></el-option>
          </el-select>
        </div>
        <div class="tops">
          <el-button size="mini" type="primary" @click="exportJilu">导出报销记录</el-button>
        </div>
      </div>

      <el-table :data="tableData" stripe style="width: 100%" :row-style="{ height: '50px' }">
        <el-table-column prop="reimburse_time" label="提交时间" sortable></el-table-column>
        <el-table-column prop="reimburse_no" label="报销编号" align="center"></el-table-column>
        <el-table-column prop="department" label="费用所属单位" align="center" ></el-table-column>
        <el-table-column prop="firm" label="报销公司" align="center"></el-table-column>
        <el-table-column prop="project_no" label="项目编号" align="center"></el-table-column>
        <el-table-column prop="reimburse_user" label="报销人" align="center"></el-table-column>
        <el-table-column prop="cost_type" label="费用种类" align="center"></el-table-column>
        <el-table-column prop="amount" label="报销金额(元)" align="center"></el-table-column>
        <el-table-column prop="invoice_name" label="是否有发票" align="center"></el-table-column>
        <el-table-column prop="detail" label="报销事项" align="center"></el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-search" @click="editfun(scope.row.reimburse_id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!--  弹窗内容-->
    <dia :dialogVisible="dialogVisible" :isAddorEdit="isAddorEdit" :infoObj="infoObj" @handleClose="handleClose" @update="Getlist" />
  </div>
</template>
<script>
import Fenye from '../../../components/Fenye'
import dia from './components/jiluDialog.vue'
import { GetJiluDetail, GetJilulist, GetJiluExport, GetJiluSearch } from '../../../api/shouhuo'
export default {
  components: {
    Fenye,
    dia
  },
  data() {
    return {
      dialogVisible: false, // 弹窗控制
      infoObj: {},
      isAddorEdit: '',
      fa: '',
      feiyong: '',
      company: '',
      daqu: '',
      userid: '',
      renlist: [],
      yue: '',
      tableData: [],
      orgs: [],
      fees: [],
      companis: [],
      bills: [
        {
          key: '',
          val: '全部'
        },
        {
          key: '1',
          val: '有发票'
        },
        {
          key: '0',
          val: '无发票'
        }
      ],
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      }
    }
  },
  mounted() {
    this.Getlist()
    this.Getshaixuan()
  },
  methods: {
    handlefindComp() {
      this.company = ''
      this.Getlist()
    },

    handledaqu() {
      this.daqu = ''
      this.Getlist()
    },

    handlefeiyong() {
      this.feiyong = ''
      this.Getlist()
    },

    handleuserid() {
      this.userid = ''
      this.Getlist()
    },

    handlefa() {
      this.fa = ''
      this.Getlist()
    },

    async editfun(id) {
      const { data } = await GetJiluDetail({ reimburse_id: id })
      console.log(data, 'data')
      if (data.code !== 200) {
        return this.$message.error('获取详情失败')
      }
      // return
      let obj = data.data
      obj.id = id
      this.infoObj = JSON.parse(JSON.stringify(obj))

      // 打开弹窗
      this.opentan('edit')
    },
    // 打开弹窗
    opentan(type) {
      this.dialogVisible = true
      this.isAddorEdit = type
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
    },
    chdaqu() {
      console.log(this.daqu)
      this.Getlist()
    },
    findComp() {
      console.log(this.company)
      this.Getlist()
    },
    chdafei() {
      console.log(this.feiyong)
      this.Getlist()
    },
    chdafapiao() {
      console.log(this.fa)
      this.Getlist()
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Getlist()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Getlist()
    },
    // 时间
    gettime(yue) {
      this.Getlist()
    },
    // 选中人
    choicedrug() {
      this.Getlist()
    },
    async Getlist() {
      let param = {
        ...this.pageData,
        month: this.yue,
        user: this.userid,
        proj: this.daqu,
        cost_type: this.feiyong,
        // invoice: this.fa,
        firm: this.company
      }
      if (this.fa != '') {
        param.invoice = this.fa
      }
      param.status = '3'
      const { data } = await GetJilulist(param)
      this.pageData.count = data.data.count
      // this.pageData.page = data.data.page * 1
      if (data.data.data.length !== 0) {
        data.data.data.map(item => {
          if (item.invoice == 1) {
            item.invoice_name = '有发票'
          } else {
            item.invoice_name = '无发票'
          }
          item.amount = item.amount / 100
          item.amount = item.amount.toFixed(2)
        })
      }
      this.tableData = data.data.data
      console.log(this.tableData, 'this.tableData')
      this.getanpostfun(data, '获取')
    },
    async exportJilu() {
      let roles = localStorage.getItem('roleMobile')
      // console.log(roles, 'roles')
      // return
      let params = {}
      // if (roles == '18561321433' || roles == '17854260201') {
        // params.month = this.yue
        // params.user = this.userid
        // params.proj = this.daqu
        // params.cost_type = this.feiyong
        // invoice: this.fa,
        // params.firm = this.company
        params.status = '3'
      // }
      if (this.fa != '') {
        params.invoice = this.fa
      }
      if (this.yue != '') {
        params.month = this.yue
      }
      if (this.userid != '') {
        params.user = this.userid
      }
      if (this.daqu != '') {
        params.proj = this.daqu
      }
      if (this.feiyong != '') {
        params.cost_type = this.feiyong
      }
      if (this.company != '') {
        params.firm = this.company
      }
      const { data } = await GetJiluExport(params)
      console.log(data, 'data')
      let url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
      // 创建a标签，并隐藏a标签
      let link = document.createElement('a')
      link.style.display = 'none'
      // a标签的href属性指定下载链接
      link.href = url
      //setAttribute() 方法添加指定的属性，并为其赋指定的值
      // 后缀格式.csv/.xsls要和需要和后端返回格式相同，这里以.csv为例
      link.setAttribute('download', '报销记录')
      document.body.appendChild(link)
      link.click()
      // this.renlist = data.data
    },

    async Getshaixuan() {
      const { data } = await GetJiluSearch({ status: '3' })
      // console.log(data.data);
      this.orgs = data.data.projs
      // console.log(this.orgs)
      this.fees = data.data.cost_type
      // console.log(this.fees)
      // this.bills = data.data.bills
      this.companis = data.data.firms
      this.renlist = data.data.users
      // console.log(this.bills)
    },

    // 公共方法
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style>
.herd {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.aaass {
  box-sizing: border-box;
}

.picsya {
  position: relative;
}

.span {
  font-weight: 300;
  position: absolute;
  top: 25px;
  left: 18px;
  color: #545c64;
  font-size: 30px;
  z-index: 10000;
  pointer-events: none;
}
</style>
