import axios from '@/api/index'

// 获取车辆详情信息
export const getList = params => {
  return axios.request({
    url: '/auto/one',
    method: 'get',
    params
  })
}

// 车辆列表详情里面 删除本车辆 /auto/del
export const Endshan = params => {
  return axios.request({
    url: '/auto/del',
    method: 'get',
    params
  })
}
// 创建加油码
export const GetRefuelingQr = (params) => {
  return  axios.request({
    url: '/auto/refueling_qr',
    method: 'get',
    params,
  })
}